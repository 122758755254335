import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GolferService from "../../services/api/golfer";
import EditIcon from '@mui/icons-material/Edit';
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import {
  update_golfer,
  update_address,
  update_billing_address,
  update_guardian
} from "../../features/golfer/golferSlice";

export default function GolferDetails(props) {
  const navigate = useNavigate();
  const golferData = useSelector((state) => state.golfer.golfer);
  const guardian_data = useSelector((state) => state.golfer.guardian);
  const store_billing_address_data = useSelector(state => state.golfer.billing_address)

  const dispatch = useDispatch();
  const [golferDetails, setGolferDetails] = useState({});
  const [primaryAddress, setPrimaryAddress] = useState({});
  const [secondaryAddress, setSecondaryAddress] = useState({});
  useEffect(() => {
    GolferService.showGolfer(golferData.primary_club_id, golferData.id)
      .then((res) => {
        setGolferDetails({
          name: res.golfers.first_name + " " + res.golfers.last_name,
          phone_number: res.golfers.phone_number || "",
          email: res.golfers.email || "",
          date_of_birth: res.golfers.date_of_birth || ""
        });
        const new_golfer_data = {
          first_name: res.golfers.first_name,
          last_name: res.golfers.last_name,
          date_of_birth: res.golfers.date_of_birth,
          phone_number: res.golfers.phone_number,
          gender: res.golfers.gender,
          has_active_guardians: res.golfers.has_active_guardians,
          email: res.golfers.email,
          has_digital_profile: res.golfers.has_digital_profile,
          is_minor: res.golfers.is_minor
        };
        dispatch(update_golfer({ ...new_golfer_data }));
        setPrimaryAddress({
          street: res.golfers.primary_address.street_1,
          street_2: res.golfers.primary_address.street_2,
          city: res.golfers.primary_address.city,
          state: res.golfers.primary_address.state,
          zip: res.golfers.primary_address.zip,
          country: res.golfers.primary_address.country,
          active_from: moment(res.golfers.primary_address.active_from, "MM/DD").format(
            "MMM DD"
          ),
          active_to: moment(res.golfers.primary_address.active_to, "MM/DD").format(
            "MMM DD"
          ),
        });
        dispatch(
          update_address({
            street_1: res.golfers.primary_address.street_1,
            street_2: res.golfers.primary_address.street_2,
            city: res.golfers.primary_address.city,
            state: res.golfers.primary_address.state,
            zip: res.golfers.primary_address.zip,
            country: res.golfers.primary_address.country || "United States",
            zip2: res.golfers.secondary_address.zip,
          })
        );
        setSecondaryAddress({
          street: res.golfers.secondary_address.street_1,
          street_2: res.golfers.secondary_address.street_2,
          city: res.golfers.secondary_address.city,
          state: res.golfers.secondary_address.state,
          zip: res.golfers.secondary_address.zip,
          country: res.golfers.secondary_address.country,
          active_from: res.golfers.secondary_address.active_from ? moment(
            res.golfers.secondary_address.active_from, "MM/DD"
          ).format("MMM DD") : "",
          active_to: res.golfers.secondary_address.active_to ? moment(
            res.golfers.secondary_address.active_to, "MM/DD"
          ).format("MMM DD") : "",
        });
        
    
        const date_of_birth = new_golfer_data.date_of_birth;
        const address = res.golfers.primary_address.street_1;
        const no_address = (address === null || address === "" || address === 'undefined')

        if (!no_address && (!store_billing_address_data || Object.keys(store_billing_address_data).length === 0))
          dispatch(update_billing_address({
            street_1: res.golfers.primary_address.street_1,
            street_2: res.golfers.primary_address.street_2,
            city: res.golfers.primary_address.city,
            state: res.golfers.primary_address.state,
            zip: res.golfers.primary_address.zip,
            country: res.golfers.primary_address.country || "United States",
          }));

        if (date_of_birth === null || date_of_birth === "" || date_of_birth === 'undefined'){
          let nav_state = { previous_page: '..' }
          if (!no_address) {
            nav_state = { ...nav_state, navigate_to: '../golfer_details' }
          }
          navigate(`../golfer`, {  
            state: nav_state
          })
        }
        else if (no_address)
          navigate(`../address`, {
            state: {
              previous_page: '..',
            }
          })
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  
  const parsePhone = (phone) => {
    if (phone !== null && phone !== undefined && phone !== ""){
      const val = phone.toString();
      if (val.length === 10)
        phone = `(${phone.slice(0,3)}) ${phone.slice(3,6)}-${phone.slice(6,10)}`;
    }
    
    return phone;
  }

  return (
    <Fragment>
        <div className="box-panel center">
          <div className="container">
          <div className="important-full-width">
              <span className="header-text">
                <strong>Before continuing, please verify the following details.</strong>
            </span>
          </div>
          <div className="row no_right_margin no_left_margin top-margin important-full-width">
            <div className="col is-6-of-8 no-left-padding">
              <span className="mini-title">ACCOUNT DETAILS</span>
              <span className="small_top_margin">{golferDetails.name}</span>
              {golferDetails.phone_number && <span className="small_top_margin">{parsePhone(golferDetails.phone_number)}</span>}
              <span className="small_top_margin">{golferDetails.email}</span>
              <span className="small_top_margin">{moment(golferDetails.date_of_birth).format('MM/DD/YYYY')}</span>
            </div>
            <div className="col is-2-of-8 ai-fe">
              <button
                className="custom-link"
                onClick={() =>
                  navigate(`../golfer`, {
                    state: {
                      previous_page: 'golfer_details'
                    }
                  })}
              >
                <EditIcon className="center_icon align-right small_icon" />
                Edit
              </button>
            </div>
            </div>

            <div className="col-display left top-margin">
              <div className="row-display">
                <span className="mini-title">PRIMARY ADDRESS</span>
              <span>{primaryAddress.street}</span>
              {primaryAddress.street_2 && <span>{primaryAddress.street_2}</span>}
              <span>
                {[primaryAddress.city, primaryAddress.state, primaryAddress.zip].filter(e => !!e).join(", ")}
                </span>
              <span>{primaryAddress.country || ""}</span>
              {primaryAddress.active_from !== 'Invalid date' && primaryAddress.active_to !== 'Invalid date' && (
                  <span>
                  From: {primaryAddress.active_from === 'Invalid date' ? "" : primaryAddress.active_from} -{" "}
                  {primaryAddress.active_to === 'Invalid date' ? "" : primaryAddress.active_to}
                  </span>
                )}
            </div>
            {secondaryAddress.street && secondaryAddress.city && (
              <div className="row-display" style={{ marginLeft: "75px" }}>
                <span className="mini-title">SECONDARY ADDRESS</span>
                  <>
                    <span>{secondaryAddress.street}</span>
                  {secondaryAddress.street_2 && <span>{secondaryAddress.street_2}</span>}
                  <span>
                    {[secondaryAddress.city, secondaryAddress.state, secondaryAddress.zip].filter(e => !!e).join(", ")}
                    </span>
                    <span>{secondaryAddress.country}</span>
                    <span>
                      From: {secondaryAddress.active_from || ""} -{" "}
                      {secondaryAddress.active_to || ""}
                    </span>
                </>
              </div>
            )}
          </div>
          <div className="row">
            <div className="col is-1-of-2">
              <button
                style={{ marginTop: '40px'}}
                className="btn smaller fill gray"
                type={"button"}
                onClick={
                  () => navigate("..")
                }
              >
                Go Back
              </button>
            </div>
            <div className="col is-1-of-2">
              <button
                style={{ marginTop: '40px' }}
                className="btn smaller fill cardinal"
                type={"submit"}
                onClick={() => navigate(`../membership`)}
              >
                Looks good. Continue
              </button>
            </div>
          </div>
          </div>
        </div>
    </Fragment>
  );
}
