import React, {Fragment, useEffect, useState, useContext} from 'react';
import {FormControl, FormControlLabel, Radio, RadioGroup} from "@mui/material"
import {useDispatch, useSelector} from "react-redux";
import {
  update_memberships,
  update_selected_membership,
  clear_memberships,
  clear_selected_membership
} from "../../features/membership/membershipSlice";
import { clear_guardian } from '../../features/golfer/golferSlice';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import moment from "moment/moment";
import {processErrorMessage} from "../../shared/errorHelper";
import JoinPageService from "../../services/api/join_page";
import ConfirmationModalTypeB from "../modals/ConfirmationModalTypeB";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { sendGAFormEvent } from "../../tracking/ga";
import { JoinPageContext } from '../../context/join_page_context';

function RestrictedMembership({id, name, amount, description, minimum_age, maximum_age, gender, membership_type}) {
  const handleRadioClick = (event) => {
    event.preventDefault();
  };

  const getAgeText = () => {
    if (minimum_age && maximum_age) {
      return `${minimum_age} to ${maximum_age}`;
    } else if (minimum_age) {
      return `Age ${minimum_age} and older`;
    } else if (maximum_age) {
      return `No older than ${maximum_age}`;
    } else {
      return 'No age restrictions';
    }
  };

  return (
    <FormControlLabel
      key={`membership_${id}`}
      value={id}
      control={<Radio onClick={handleRadioClick}/>}
      label={
        <div className="unavailable-membership" style={{marginBottom: '1em'}}>
          <p style={{margin: 0, fontWeight: 600}}>{name} - ${parseFloat(amount).toFixed(2)}</p>
          <p style={{margin: 0, fontWeight: 600}}>{description}</p>{getAgeText() && (
          <p style={{margin: 0}}><u>Age</u>: {getAgeText()}</p>)}
          {gender && (<p style={{margin: 0}}>
            <u>Gender</u>: {gender === 'male and female' ? 'Male & Female' : gender.slice(0, 1).toUpperCase() + gender.slice(1)}
          </p>)}
          <p style={{margin: 0}}><u>Membership Type</u>: {membership_type}</p>
          <p style={{margin: 0}}><u>Zip</u>: The zip code must be within a 100-mile radius of the club's zip code or must match a valid zip code approved for this club</p>
        </div>
      }
    />
  );
}

function Membership() {
  const {url, club_id} = useParams();
  const {state} = useLocation();
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { JoinPageData } = useContext(JoinPageContext);

  const [errorMessage, setErrorMessage] = useState(false);
  const store_golfer_data = useSelector(state => state.golfer.golfer) || {}
  const store_club_data = useSelector(state => state.club.club_data) || {}
  const [unfilteredMemberships, setUnfilteredMemberships] = useState([]);
  const [memberships, setMemberships] = useState([]);
  const [loadingMemberships, setLoadingMemberships] = useState(false);
  const previousMembership = useSelector(state => state.membership.selected_membership);
  const [selectedMembership, setSelectedMembership] = useState(previousMembership?.id ? previousMembership.id : null);
  const zip = useSelector(state => [state.golfer.address.zip, state.golfer.address.zip2])

  const filterMemberships = (memberships, on_limit) => {
    
    if (on_limit === false)
      return [];
    
    let {date_of_birth, gender} = store_golfer_data;
    if (gender === 'M')
      gender = 'male'
    else if (gender === 'F')
      gender = 'female'
    const age = moment().diff(moment(date_of_birth).format('YYYY/MM/DD'), 'years');

    return memberships.filter(membership => {
      if (age >= 19 && membership.membership_type !== "Standard")
        return false;
      if (membership.gender != null && membership.gender !== "male and female" && membership.gender !== gender)
        return false;
      if (membership.minimum_age != null && membership.minimum_age > age)
        return false;
      if (membership.maximum_age != null && membership.maximum_age < age)
        return false;
      return true;
    });
  };
  
  useEffect(() => {
    const email = store_golfer_data.email;
    if (email !== null && email !== "" && email !== 'undefined')
      dispatch(clear_guardian());
  }, [])

  useEffect(() => {
    setLoadingMemberships(true);
    const data = {
      zip_code: zip
    };
    JoinPageService.getClubJoinPageInformation(url, club_id, data)
      .then(res => {
        const data = {...res.join_page.club_membership_fees};
        const allMemberships = Object.values(data);
        const on_limit = res.join_page.on_limit;
        const filteredMemberships = filterMemberships(allMemberships, on_limit)
        const selectedMembershipExists = filteredMemberships.find(membership => membership.id === selectedMembership) !== undefined;
        if (!selectedMembershipExists) {
          setSelectedMembership(null);
          dispatch(clear_selected_membership())
        }
        setMemberships(filteredMemberships);
        setUnfilteredMemberships(allMemberships);
        dispatch(clear_memberships())
        dispatch(update_memberships({...filteredMemberships}));
        setLoadingMemberships(false);
      })
      .catch(err => {
        setLoadingMemberships(false);
        setErrorMessage(processErrorMessage(err.data.errors))
        console.log(err);
      })
  }, [store_golfer_data, club_id]);
  
  const sendGAFormEventData = () => {
    if (store_golfer_data.id) {
      const user_properties = {
        club_id: club_id,
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: store_golfer_data.id
      }
      sendGAFormEvent(user_properties, "Existing Golfer - Select Membership Form")
    } else {
      const user_properties = {
        club_id: club_id,
        association_id: JoinPageData.golf_association_details.id.toString(),
        ghin_number: "-"
      }
      sendGAFormEvent(user_properties, "New Golfer - Select Membership Form")
    }
  }

  return (
    <Fragment>

      <div className='page_container no_padding membership_page'>
        {!loadingMemberships && memberships.length <= 0 &&
          <div className='panel_box'>
            <p className={"error-text semi-bold"}>No Memberships Available</p>
            <p>
              We are sorry, but due to one or more of the eligibility requirements
              listed below, there are no memberships available that match the
              criteria you have provided. Please use the “Go Back” button to edit
              your details, or contact the association.
            </p>
          </div>
        }
        <div className='box-panel center'>
          <div className='container'>
            {memberships.length > 0 &&
              <div className='center'>
              <span className='header-text'>
                <strong>Select your membership fee</strong>
              </span>
              </div>
            }
            <div style={{paddingTop: memberships.length > 0 ? '20px' : undefined, maxWidth: '100%'}}>
              <FormControl>
                {loadingMemberships && <span>Loading...</span>}
                {!loadingMemberships && memberships.length > 0 ? (
                  <RadioGroup
                    aria-labelledby="membership-group"
                    name="membership-group"
                    value={selectedMembership}
                    onChange={(e) => {
                      const id = parseInt(e.target.value)
                      setSelectedMembership(id)
                    }}
                  >
                    {
                      memberships.map((e) => {
                        return <FormControlLabel
                          key={`membership_${e.id}`}
                          value={e.id}
                          control={<Radio/>}
                          label={
                            e.hasOwnProperty('prorated_amount') ?
                              <div className={"codes-display-c"} style={{marginBottom: "1em"}}>
                                <p style={{margin: 0}}>
                                  <span style={{fontWeight: 600}}>{e.name} -</span>
                                  &nbsp;
                                  <span style={{whiteSpace: "nowrap"}}>
                                    <span style={{color: 'red'}}>${parseFloat(e.prorated_amount).toFixed(2)}</span>
                                    &nbsp;&nbsp;
                                    <span className="original-fee">Reg <span
                                      style={{textDecoration: 'line-through'}}>${parseFloat(e.amount).toFixed(2)}</span>
                                    </span>
                                  </span>
                                </p>
                                <p style={{margin: 0}}>{e.description}</p>
                              </div>
                              :
                              <div style={{marginBottom: "1em"}}>
                                <p style={{margin: 0, fontWeight: 600}}>{e.name} -
                                  ${parseFloat(e.amount).toFixed(2)}</p>
                                <p style={{margin: 0}}>{e.description}</p>
                              </div>}
                        />
                      })}
                  </RadioGroup>
                ) : !loadingMemberships &&
                  (
                    <div className="disabled_memberships">
                      {unfilteredMemberships.map((m) => <RestrictedMembership key={m.id} {...m}/>)}
                    </div>
                  )
                }
              </FormControl>
            </div>
            <div className="row">
              <div className="col is-1-of-2">
                <button
                  style={{ marginTop: '40px'}}
                  className={memberships.length > 0 ? "btn med fill gray" : "btn x-smaller fill gray"}
                  type={"button"}
                  onClick={() => {
                    if (state && state.previous_page && (state.previous_page === 'address' || state.previous_page === 'guardian'))
                      navigate(`../${state.previous_page}`);
                    else
                      navigate(`../golfer_details`);
                  }}
                >
                  Go Back
                </button>
              </div>
              <div className="col is-1-of-2">
                <button
                  style={{ marginTop: '40px'}}
                  className={memberships.length > 0 ? "btn med fill cardinal" : "btn x-smaller fill cardinal"}
                  type={"submit"}
                  onClick={() => {
                    if (memberships.length > 0) {
                      dispatch(update_selected_membership({ id: selectedMembership }));
                      sendGAFormEventData();
                      navigate(`../payment`)
                    } else
                      navigate(`../`)
                  }}
                  disabled={selectedMembership === null && memberships.length > 0}
                >
                  {memberships.length > 0 ? 'Continue to Payment' : 'Cancel'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModalTypeB
        wideClass={'responsive'}
        openModal={errorMessage.length > 0}
        onConfirmAction={() => setErrorMessage('')}
        onCancelAction={() => setErrorMessage('')}
        closeModal={() => setErrorMessage('')}
        question={errorMessage}
        confirmLabel={"Confirm"}
        modalIcon={CheckCircleOutlineIcon}
      />

    </Fragment>
  );
}

export default Membership;
